section.emoji-mart {
  background-color: #383842;
  position: absolute;
  left: 0px;
  bottom: 105%;
}

section.emoji-mart [class^="emoji-mart"],
section.emoji-mart [id^="emoji-mart"],
section.emoji-mart .emoji-mart-category-label span {
  background-color: inherit;
}

section.emoji-mart button[class^="emoji-mart"],
section.emoji-mart button[class^="emoji-mart"] span {
  cursor: pointer;
}

section.emoji-mart .emoji-mart-category button.emoji-mart-emoji:hover::before {
  background-color: #555;
}

section.emoji-mart .emoji-mart-bar {
  border-width: 2px;
  border-color: #383842;
}

section.emoji-mart .emoji-mart-scroll {
  height: 250px;
}

@media screen and (max-height: 630px) {
  section.emoji-mart {
    top: -347px;
  }
  
  section.emoji-mart .emoji-mart-scroll {
    height: 180px;
  }

  section.emoji-mart .emoji-mart-preview .emoji-mart-title-label {
    font-size: 22px;
  }
}